import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav } from 'react-bootstrap'
import NavIcon from "./navIcon"

const Header = ({ siteTitle, menuItems }) => (
  <Navbar expand="lg" className='border-bottom shadow-sm mb-3 px-5 py-3'>
    <Navbar.Brand as={Link} to="/" style={{color:"#00668F"}}><NavIcon className="mx-2" />{siteTitle}</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="block ms-auto text-center">
        {menuItems.map((menuItem) =>
          <Nav.Item>
            <Link to={menuItem.link} className="nav-link" activeClassName='active'>{menuItem.name}</Link>
          </Nav.Item>
        )}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuItems: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  menuItems: [{name: "home", link: "/"}],
}


export default Header
