/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuItems {
            name
            link
            subPages {
              link
              name
            }
          }
          title
        }
      }
    }
  `)

  return (
    <div className="contain">
      <Header 
        siteTitle={data.site.siteMetadata?.title || `Title`}
        menuItems={data.site.siteMetadata?.menuItems || [{name: "home", link: "/"}]} 
      />
      <div>
        {children}
      </div>
      <Footer services={data.site.siteMetadata?.menuItems.filter(item => item.name === "Services")[0].subPages} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
