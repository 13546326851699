import React from "react"
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { FaStar } from "react-icons/fa"


const Footer = ({ services }) => {
    const data = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(relativePath: { eq: "Esseltine-Marketing-Logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }
            }
        `
    )

    return (
        <footer className="bg-light mt-auto">
            <Row className="justify-content-around justify-content-sm-center mw-100 py-4 mx-auto align-items-center">
                <Col sm={5} lg={3} className="text-center p-4">
                    Services:
                    <ListGroup className="px-">
                        {services.map((service) =>
                            <ListGroupItem>
                                <Link to={"/services" + service.link}>
                                    {service.name}
                                </Link>
                            </ListGroupItem>
                        )}
                    </ListGroup>
                </Col>
                <Col sm={5} lg={3} className="p-4">
                    <Img fluid={data.placeholderImage.childImageSharp.fluid} style={{maxWidth: '400px'}} className="mx-auto" />
                </Col>
                <Col sm={5} lg={3} className="text-center p-4">
                    <div itemScope itemType="https://schema.org/LocalBusiness" className='m-2 text-center'>
                        <span itemProp="name">Esseltine Marketing</span>
                        <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                            <span itemProp="streetAddress">189 Park Ave</span><br />
                            <span itemProp="addressLocality">Cambridge</span>, <span itemProp="addressRegion">ON</span> <span itemProp="postalCode">N1S 2S5</span>
                        </div>
                        <a href="tel:+15192225450"><span itemProp="telephone">519-222-5450</span></a>
                    </div>
                    <span>esseltinemarketing.com</span><br />
                    <span>shawn@esseltinemarketing.com</span>
                </Col>
                <Col sm={5} lg={3} className="text-center p-4">
                    Rated:<br/>
                    {Array.from({length: 5}, (v, i) => <FaStar style={{color: "#ffd700"}}/>)}<br/>
                    (5 Stars)<br/>
                    on Google
                </Col>
            </Row>
            <div className="bg-dark text-light p-5 text-center">
                &copy; {new Date().getFullYear()} Esseltine Marketing
            </div>
        </footer>
    )
}


Footer.propTypes = {
    services: PropTypes.array
  }

export default Footer