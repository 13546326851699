import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const NavIcon = () => {
    const data = useStaticQuery(graphql`
      query{
        placeholderImage: file(relativePath: { eq: "Esseltine-Marketing-Icon-Square.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)
  
    if (!data?.placeholderImage?.childImageSharp?.fixed) {
      return <div>Picture not found</div>
    }
  
    return <Img fixed={data.placeholderImage.childImageSharp.fixed} className="align-top"/>
  }

  export default NavIcon 